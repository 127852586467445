"use client";

import React from "react";
import styles from "./Carousel.module.scss";
import For from "@/components/For";
import H2 from "@/components/H2";
import AnimateIn from "@/components/AnimateIn";
import Image from "@/components/Image";

/**
 *
 * Carousel
 *
 */
const Carousel = ({ data = {}, isInterior = false, ...props }) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
  };
  const thumbnails = data?.imagesCollection?.items || [];

  return (
    <section
      className={`${styles.carousel} px-gutter stack gap-6 pt-32`}
      {...props}
    >
      <AnimateIn>
        <H2>Look around</H2>
      </AnimateIn>
      <div className="flex gap-4 flex-col md:flex-row">
        <AnimateIn as="div" className="md:w-3/4">
          <Image
            src={thumbnails?.[activeIndex]?.url || ""}
            alt="Large Image"
            fillContainer
            imgClassName="rounded overflow-hidden aspect-[978/751]"
          />
        </AnimateIn>
        <AnimateIn
          delay="delay-1"
          as="div"
          className="md:w-1/4 md:grid md:grid-cols-2 flex gap-4 justify-start h-max overflow-x-auto overflow-y-hidden md:overflow-hidden"
        >
          <For
            each={thumbnails}
            render={(thumbnail, index) => (
              <AnimateIn delay={index % 2 === 0 ? "" : "delay-1"}>
                <button
                  key={index}
                  onClick={() => handleThumbnailClick(index)}
                  className={`rounded overflow-hidden w-30 md:w-auto aspect-square h-max ${
                    index === activeIndex
                      ? "border-2 border-teal"
                      : "border-2 border-transparent"
                  }`}
                >
                  <Image
                    src={thumbnail?.url}
                    alt={`Thumbnail ${index}`}
                    className="md:aspect-[1.05/1]"
                    imgClassName="md:aspect-[1.05/1]"
                    fillContainer
                  />
                </button>
              </AnimateIn>
            )}
          />
        </AnimateIn>
      </div>
    </section>
  );
};

export default Carousel;
