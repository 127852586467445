export const dataMap = {
  "Independent Living": ["The Marquardt", "The Cesta"],
  "Assisted Living / Memory Care": [
    "The Marquardt",
    "Lutheran Homes and Health Services",
    "St. Anne’s",
    "LindenGrove Waukesha",
    "LindenGrove Mukwonago",
    "LindenGrove New Berlin",
  ],
  "Assisted Living/Memory Care": [
    "The Marquardt",
    "Lutheran Homes and Health Services",
    "St. Anne’s",
    "LindenGrove Waukesha",
    "LindenGrove Mukwonago",
    "LindenGrove New Berlin",
  ],
  "Assisted Living": [
    "The Marquardt",
    "Lutheran Homes and Health Services",
    "St. Anne’s",
  ],
  "Memory Care": [
    "The Marquardt",
    "Lutheran Homes and Health Services",
    "St. Dominic Villa",
  ],
  "Affordable Housing": [
    "Park Hill, Watertown",
    "Trinity Terrace, Racine",
    "Lincoln Villas North, Racine",
    "McKee Apartments, Fitchburg",
  ],
  "Skilled Nursing": [
    "The Marquardt",
    "Lutheran Homes and Health Services",
    "St. Elizabeth",
    "St. Anne’s",
    "St. Dominic Villa",
  ],
  "Skilled Nursing / Rehab": [
    "The Marquardt",
    "Lutheran Homes and Health Services",
    "St. Elizabeth",
    "St. Anne’s",
    "St. Dominic Villa",
    "LindenGrove Menomonie Falls",
    "LindenGrove Waukesha",
    "LindenGrove Mukwonago",
    "LindenGrove New Berlin",
  ],
  Rehab: [
    "The Marquardt",
    "Lutheran Homes and Health Services",
    "St. Elizabeth",
    "St. Anne’s",
    "St. Dominic Villa",
  ],
  "Skilled Nursing/Rehab": [
    "The Marquardt",
    "Lutheran Homes and Health Services",
    "St. Elizabeth",
    "St. Anne’s",
    "St. Dominic Villa",
    "LindenGrove Menomonie Falls",
    "LindenGrove Waukesha",
    "LindenGrove Mukwonago",
    "LindenGrove New Berlin",
  ],
  Giving: [
    // "The Marquardt"
  ],
  "Career Opportunities": [
    "The Marquardt",
    "Lutheran Homes and Health Services",
    "St. Elizabeth",
    "St. Anne’s",
    "St. Dominic Villa",
    "Illuminus",
    "LindenGrove Menomonee Falls",
    "LindenGrove Waukesha",
    "LindenGrove Mukwonago",
    "LindenGrove New Berlin",
  ],
};

export const reverseDataMap = {
  "the-marquardt": [
    "Independent Living",
    "Assisted Living / Memory Care",
    "Skilled Nursing / Rehab",
  ],
  "the-cesta": ["Independent Living"],
  "lutheran-homes-and-health-services": [
    "Assisted Living / Memory Care",
    "Skilled Nursing / Rehab",
  ],
  "st-elizabeth": ["Skilled Nursing / Rehab"],
  "st-annes": ["Assisted Living / Memory Care", "Skilled Nursing / Rehab"],
  "st-dominic-villa": ["Skilled Nursing / Rehab"],
  "Park Hill, Watertown": ["Affordable Housing"],
  "Trinity Terrace, Racine": ["Affordable Housing"],
  "Lincoln Villas North, Racine": ["Affordable Housing"],
  "McKee Apartments, Fitchburg": ["Affordable Housing"],
  "lindengrove-new-berlin": [
    "Assisted Living / Memory Care",
    "Skilled Nursing / Rehab",
  ],
  "lindengrove-waukesha": [
    "Assisted Living / Memory Care",
    "Skilled Nursing / Rehab",
  ],
  "lindengrove-menomonee-falls": ["Skilled Nursing / Rehab"],
  "lindengrove-mukwonago": [
    "Assisted Living / Memory Care",
    "Skilled Nursing / Rehab",
  ],
};

export const placeMap = {
  "the-marquardt": "The Marquardt",
  "the-cesta": "The Cesta",
  "lutheran-homes-and-health-services": "Lutheran Homes and Health Services",
  "st-elizabeth": "St. Elizabeth",
  "st-annes": "St. Anne’s",
  "st-dominic-villa": "St. Dominic Villa",
  "park-hill-watertown": "Park Hill, Watertown",
  "trinity-terrace-racine": "Trinity Terrace, Racine",
  "lincoln-villas-north-racine": "Lincoln Villas North, Racine",
  "mckee-apartments-fitchburg": "McKee Apartments, Fitchburg",
  "lindengrove-new-berlin": "LindenGrove New Berlin",
  "lindengrove-waukesha": "LindenGrove Waukesha",
  "lindengrove-menomonie-falls": "LindenGrove Menomonie Falls",
  "lindengrove-mukwonago": "LindenGrove Mukwonago",
};
