export default function Play({ className = "", width = 44, height = 44 }) {
  return (
    <svg
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} max-w-full`}
    >
      <circle cx="22" cy="22" r="22" fill="#FFB154" />
      <path d="M26 22L20 25.4641L20 18.5359L26 22Z" fill="#0C2340" />
    </svg>
  );
}
