import React from "react";
import styles from "./Stack.module.scss";

const StackValues = {
  none: "gap-0",
  page: "gap-32",
  lockup: "gap-16",
  tight: "gap-8",
};

interface StackProps {
  as?: React.ElementType;
  gap?: keyof typeof StackValues;
  className?: string;
  children?: React.ReactNode;
}

/**
 *
 * Stack
 *
 */
const Stack = ({
  as = "div",
  gap = "none",
  className = "",
  children = null,
  ...props
}: StackProps) => {
  const Tag = as;
  return (
    <Tag className={`stack w-full ${StackValues[gap]} ${className}`} {...props}>
      {children}
    </Tag>
  );
};

export default Stack;
