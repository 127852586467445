"use client";

import React from "react";
import * as Select from "@radix-ui/react-select";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";
import clsx from "clsx";

import For from "@/components/For";

import styles from "./Select.module.scss";
import { ChevronDown, ChevronUp } from "react-feather";

interface SelectProps {
  options: { value: string; label: string; disabled?: boolean }[];
  value?: string;
  name: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  className?: string;
}

interface SelectItemProps {
  children: React.ReactNode;
  className?: string;
}

/**
 *
 * Select
 *
 */
const SelectInput = ({
  options = [],
  value = "",
  name = "",
  onChange = (_) => {},
  className = "",
  placeholder = "",
  ...props
}: SelectProps) => {
  return (
    <div className={`${styles.select}`} {...props}>
      <Select.Root name={name} onValueChange={(value) => onChange(value)}>
        <Select.Trigger
          className={`rounded-button border border-border-color text-border-color w-full h-[70px] flex justify-between items-center text-left px-10 whitespace-nowrap leading-none select overflow-hidden ${className}`}
        >
          <Select.Value
            placeholder={placeholder}
            className="text-left whitespace-nowrap"
          />
          <Select.Icon color="#ff0000" />
        </Select.Trigger>

        <Select.Portal>
          <Select.Content
            position="item-aligned"
            className="overflow-auto overscroll-contain bg-white rounded mt-20 py-2 w-full max-w-full shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] z-50"
          >
            <Select.ScrollUpButton className="flex items-center justify-center h-[25px] bg-white text-orange cursor-default">
              <ChevronUp width={35} />
            </Select.ScrollUpButton>
            <Select.Viewport className="p-[5px] overflow-auto overscroll-contain">
              <For
                each={options}
                render={(item) => {
                  return (
                    <SelectItem
                      key={item.value}
                      value={item.value}
                      disabled={item.disabled}
                      className=" focus-visible:outline-orange rounded"
                    >
                      {item.label}
                    </SelectItem>
                  );
                }}
              />
            </Select.Viewport>
            <Select.ScrollDownButton className="flex items-center justify-center h-[25px] bg-white text-orange cursor-default">
              <ChevronDown width={35} />
            </Select.ScrollDownButton>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    </div>
  );
};

const SelectItem = React.forwardRef(
  ({ className = "", children, ...props }: SelectItemProps, forwardedRef) => {
    return (
      <Select.Item
        className={clsx("leading-none py-4 px-6 cursor-pointer", className)}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
          <CheckIcon />
        </Select.ItemIndicator>
      </Select.Item>
    );
  }
);

export default SelectInput;
