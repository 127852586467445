import React from "react";
import styles from "./TextArea.module.scss";

/**
 *
 * TextArea
 *
 */
const TextArea = ({
  label = "",
  name = "",
  placeholder = "",
  value = "",
  background = "bg-transparent",
  required = false,
  ...props
}) => {
  return (
    <div className={styles["text-area"]} {...props}>
      <label htmlFor={name} className="sr-only">
        {label}
      </label>
      <textarea
        name={name}
        id={name}
        placeholder={placeholder || label}
        className={`rounded-button border border-border-color color-border-border-color w-full h-[200px] flex justify-between items-center px-10 py-8 leading-none ${background}`}
        required={required}
      />
    </div>
  );
};

export default TextArea;
