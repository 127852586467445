export const wait = (millis = 1000) => {
  return new Promise((res) => setTimeout(res, millis));
};

export const isPreview = () => {
  const previews = [
    "1",
    1,
    true,
    "true",
    "yes",
    "y",
    "on",
    "enabled",
    "active",
    "1",
    "true",
    "yes",
    "y",
    "on",
    "enabled",
  ];
  return previews.includes(process.env.CONTENTFUL_IS_PREVIEW);
};

export const getEnvVariable = (key, defaultValue) => {
  console.log("test", process.env["VITE_CONTENTFUL_IS_PREVIEW"]);
  return import.meta.env?.[key] || process.env?.[key];
};

export const isMobile = () => {
  if (typeof window !== "undefined") {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) ||
      window.innerWidth <= 1000
    ) {
      // true for mobile device
      return true;
    }
    return false;
  }
};

export const getImage = (data = {}) => {
  // console.log("getImage", data);
  if (data?.image) {
    return data?.image;
  }

  if (data?.cardImage) {
    return data?.cardImage;
  }

  if (data?.featuredImage) {
    return data?.featuredImage;
  }

  if (data?.linkedPage?.cardImage) {
    return data?.linkedPage?.cardImage;
  }

  if (data?.linkedPage?.featuredImage) {
    return data?.linkedPage?.featuredImage;
  }

  return {
    url: "",
    alt: "",
  };
};

export const getPrefix = (data = {}) => {
  if (!data) return "";

  if (
    data?.linkedPage?.pageType === "community" ||
    data?.pageType === "community"
  ) {
    return "/communities";
  }

  if (
    data.linkedPage?.pageType === "community service" ||
    data?.pageType === "community service"
  ) {
    return "/lifestyle-options";
  }

  return "";
};

export const getUrl = (data = {}) => {
  if (!data) return "/";
  // console.log("getUrl", data);
  if (data?.linkedPage?.slug) {
    return `${getPrefix(data)}/${data.linkedPage.slug}`;
  }

  if (data?.slug) {
    return `${getPrefix(data)}/${data?.slug}`;
  }

  if (data?.url) {
    return data?.url;
  }

  return "/";
};
