"use client";

import React, { useState } from "react";
import styles from "./CardGrid.module.scss";
import If from "@/components/If";
import For from "@/components/For";
import AnimateIn from "@/components/AnimateIn";
import { Button } from "@/components/actions/Button";
import H2 from "@/components/H2";
import Image from "@/components/Image";
import { getImage, getUrl } from "@/helpers/util";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Modal } from "@/components/overlay/Modal";
import { options } from "@/config/contentful";

const Card = ({
  card = {
    title: "",
    image: { url: "", alt: "" },
  },
  index = 0,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const image = getImage(card || {});
  const url = getUrl(card);

  return (
    <>
      <AnimateIn
        as="article"
        className={`aspect-square w-full rounded relative overflow-hidden ${styles.card}`}
        delay={`delay-${index % 5}`}
        threshold={0.25}
      >
        <div
          className={`absolute left-0 top-0 z-0 w-full h-full ${styles["gradient-overlay"]}`}
        >
          <Image
            src={image?.url}
            alt={image?.alt}
            width={image?.width}
            height={image?.height}
            shouldLazyLoad
            fillContainer
            className="relative z-0"
            maxWidth="750"
          />
        </div>
        <div
          className={`${styles.overlay} absolute grid place-content-center left-0 top-0 z-[2] w-full h-full card-grid-overlay`}
        >
          <svg
            viewBox="0 0 408 424"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-full h-full"
          >
            <path
              d="M176 339.55V388.449C176 408.063 188.552 424 204 424C219.448 424 232 408.063 232 388.449V339.55C232 319.936 219.448 304 204 304C188.552 304 176 319.936 176 339.55Z"
              fill="#6F9BA4"
            />
            <path
              d="M204 120C219.448 120 232 104.064 232 84.4495V35.5505C232 15.9364 219.448 0 204 0C188.552 0 176 15.9364 176 35.5505V84.4495C176 104.064 188.552 120 204 120Z"
              fill="#6F9BA4"
            />
            <path
              d="M339.07 240H372.93C392.279 240 408 227.448 408 212C408 196.552 392.279 184 372.93 184H339.07C319.721 184 304 196.552 304 212C304 227.448 319.721 240 339.07 240Z"
              fill="#6F9BA4"
            />
            <path
              d="M35.0698 240H68.9302C88.279 240 104 227.448 104 212C104 196.552 88.279 184 68.9302 184H35.0698C15.721 184 0 196.552 0 212C0 227.448 15.721 240 35.0698 240Z"
              fill="#6F9BA4"
            />
            <path
              d="M120.787 293.289C127.227 300.342 135.94 304 144.779 304C152.986 304 161.068 300.865 167.507 294.595L203.874 258.934L240.24 294.595C246.679 300.865 254.761 304 262.968 304C271.681 304 280.394 300.473 286.96 293.289C299.461 279.573 298.956 258.019 285.697 245.087L251.857 211.908L285.571 178.859C298.83 165.927 299.335 144.243 286.834 130.658C274.333 116.942 253.372 116.419 240.24 129.352L204 164.882L167.76 129.352C154.501 116.419 133.667 116.942 121.166 130.658C108.665 144.374 109.17 165.927 122.429 178.859L156.143 211.908L122.302 245.087C109.044 258.019 108.539 279.703 121.04 293.289H120.787Z"
              fill="#6F9BA4"
            />
          </svg>
        </div>
        <div
          className={`${styles.content} z-[3] stack gap-6 justify-end px-6 py-0 h-full relative`}
        >
          <div className="stack gap-2">
            <p className="text-white leading-tight">{card?.subTitle}</p>
            <h3 className="text-white text-card-title font-medium max-w-[80%]">
              {card?.title}
            </h3>
          </div>
          <If condition={!card?.opensModal}>
            <Button
              link={url}
              className="w-max"
              target={url?.includes?.("http") ? "_blank" : "_self"}
            >
              Learn More
            </Button>
          </If>
          <If
            condition={card?.opensModal !== null && card?.opensModal === true}
          >
            <Button
              onClick={() => {
                setModalOpen(true);
              }}
              className="w-max"
            >
              Learn More
            </Button>
          </If>
        </div>
      </AnimateIn>
      <If condition={modalOpen}>
        <Modal
          onClose={() => setModalOpen(false)}
          name={card?.title}
          image={card?.image || {}}
          description={card?.richTextDescription || {}}
          title={card?.subTitle}
        />
      </If>
    </>
  );
};

/**
 *
 * CardGrid
 *
 */
const CardGrid = ({
  data = {},
  isInterior = false,
  debug = false,
  isLast = false,
  ...props
}) => {
  const { title, subTitle, cardsCollection, description } = data;
  const cards = cardsCollection?.items || [];
  if (debug === true) {
    console.log("debugging", cards);
  }
  return (
    <section className={`stack gap-12 py-32`}>
      <If condition={description?.json && title}>
        <div className="mx-gutter">
          <p>{subTitle}</p>
          <H2>{title}</H2>
        </div>
      </If>
      <div
        className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mx-gutter`}
        {...props}
      >
        <If condition={!description?.json && title}>
          <div>
            <p>{subTitle}</p>
            <H2>{title}</H2>
          </div>
        </If>
        <If condition={description?.json}>
          {documentToReactComponents(description?.json, options)}
        </If>
        <For
          each={cards}
          render={(card, index) => {
            return <Card key={index} card={card} index={index} />;
          }}
        />
      </div>
    </section>
  );
};

export default CardGrid;
