"use client";

import React, { useRef } from "react";
import styles from "./CardBlock.module.scss";
import AnimateIn from "@/components/AnimateIn";
import For from "@/components/For";
import If from "@/components/If";
import { Stack } from "@/components/structure/Stack";
import H2 from "@/components/H2";
import { Button } from "@/components/actions/Button";
import Markdown from "react-markdown";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CardGrid } from "../CardGrid";
import Divider from "@/components/Divider";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BGColors } from "@/config/colors";
import Image from "@/components/Image";
import { getPrefix, getUrl } from "@/helpers/util";
import { options } from "@/config/contentful";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

const TextSide = ({ data = {} }) => {
  return (
    <div className="col-span-12 md:col-span-7 stack gap-12 md:pl-24 relative z-[1] justify-center text-block">
      <If condition={data.title}>
        <header className="w-full max-w-[573px]">
          <If condition={data.subTitle}>
            <p>{data.subTitle}</p>
          </If>

          <H2>{data.title}</H2>
          <Divider />
        </header>
      </If>
      <If condition={data.description}>
        <div className="w-full max-w-[573px]">
          <Markdown>{data.description}</Markdown>
        </div>
      </If>
      <If condition={data.richTextDescription}>
        <div className="max-w-[573px] stack gap-6">
          {documentToReactComponents(data.richTextDescription?.json, options)}
        </div>
      </If>
      <If condition={data.url}>
        <Button
          link={`${data?.url}`}
          className="w-max"
          target={
            data?.url?.isExternal || data?.url?.includes("http")
              ? "_blank"
              : "_self"
          }
        >
          {data.buttonText || "Learn More"}
        </Button>
      </If>
      <If condition={data.linkedPage}>
        <Button
          link={`${getPrefix(data.linkedPage)}/${data.linkedPage?.slug}`}
          className="w-max"
        >
          {data?.buttonText || data.linkedPage?.title}
        </Button>
      </If>
      <For
        each={data.ctAsCollection?.items || []}
        render={(cta, index) => (
          <Button
            key={cta.link}
            link={cta.link}
            target={cta.isExternal ? "_blank" : ""}
            className="w-max"
          >
            {cta.buttonText}
          </Button>
        )}
      />
    </div>
  );
};

const ImageSide = ({ data = {} }) => {
  const image = data.image || data?.linkedPage?.cardImage || {};
  return (
    <div className="col-span-12 md:col-span-5">
      <Image
        src={image?.url}
        alt={image?.description}
        className="relative z-[1] max-w-full  sticky top-0"
        imgClassName="rounded overflow-hidden aspect-square lg:aspect-[604/730] max-h-[900px]"
        fillContainer
        maxWidth="700"
        objectPosition={data.imagePosition}
      />
    </div>
  );
};

const Card = ({ data = {}, isInterior = false, ...props }) => {
  const card = data;

  const {
    title,
    description,
    image,
    backgroundColor = "dark-blue",
    subTitle,
    ctAsCollection,
    richTextDescription,
    imageSide = "right",
    imagePosition,
  } = card || {};

  const ref = useRef(null);
  const backgroundEle = useRef(null);

  useGSAP(
    () => {
      if (!gsap || typeof window === "undefined") {
        return;
      }

      const tl = gsap.timeline({
        paused: true,
        scrollTrigger: {
          trigger: ref.current,
          start: window.innerWidth > 600 ? "top bottom" : "top-=300 bottom",
          end: window.innerWidth > 600 ? `bottom bottom` : "bottom bottom",
          scrub: 0.25,
          onLeave: () => {
            // ref.current.style.clipPath = "inset(0 0 0 0 round 0)";
          },
        },
      });

      if (backgroundColor !== "Transparent") {
        tl.to(ref.current, {
          clipPath: "inset(0% 0% 0% 0% round var(--border-radius)",
          transform: "translateY(0)",
          ease: "none",
          onEnter: () => {},
          onEnterBack: () => {},
          onComplete: () => {
            ref.current.style.clipPath = "inset(0 0 0 0 round 0)";
          },
        });
      }

      if (!backgroundEle.current) return;

      if (
        (backgroundColor === "dark-blue" ||
          backgroundColor === "Midnight Blue") &&
        (imageSide === "right" || !imageSide)
      ) {
        tl.to(backgroundEle.current, {
          x: 150,
          ease: "power3.inOut",
        });
      }

      if (
        (backgroundColor === "dark-blue" ||
          backgroundColor === "Midnight Blue") &&
        imageSide === "left"
      ) {
        tl.to(backgroundEle.current, {
          y: -100,
          ease: "power3.inOut",
        });
      }

      if (backgroundColor?.toLowerCase() === "teal") {
        tl.to(backgroundEle.current, {
          x: -150,
          ease: "power3.inOut",
        });
      }

      if (backgroundColor?.toLowerCase() === "orange") {
        tl.to(backgroundEle.current, {
          x: -150,
          ease: "power3.inOut",
        });
      }

      if (backgroundColor === "Marquardt Light Green") {
        tl.to(backgroundEle.current, {
          y: 100,
          ease: "power3.inOut",
        });
      }

      if (backgroundColor === "Marquardt Green") {
        tl.to(backgroundEle.current, {
          x: -100,
          ease: "power3.inOut",
        });
      }
    },
    {
      dependencies: [ref.current, backgroundEle.current],
    }
  );

  return (
    <div
      ref={ref}
      className="relative z-[1]"
      data-color={backgroundColor}
      style={{
        clipPath:
          backgroundColor !== "Transparent"
            ? "inset(5% 5% 5% 5% round var(--border-radius))"
            : "",
        transform: backgroundColor !== "Transparent" ? "translateY(100px)" : "",
      }}
    >
      <article
        className={`${
          styles.card
        } flex flex-col lg:grid lg:grid-cols-12 gap-16 pl-4 items-center px-gutter md:pr-gutter py-20 relative ${
          BGColors[backgroundColor] || "bg-white"
        }`}
        {...props}
      >
        <If
          condition={
            (backgroundColor === "dark-blue" ||
              backgroundColor === "Midnight Blue") &&
            (imageSide === "right" || !imageSide)
          }
        >
          <svg
            viewBox="0 0 523 554"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mix-blend-screen absolute top-[20%] left-[-20%] z-0 w-1/2 h-[30vw]"
            ref={backgroundEle}
          >
            <g opacity="0.5" clipPath="url(#clip0_1_233)">
              <path
                d="M-247.059 0.0354614L-246.962 553.585L253.038 553.498L252.941 -0.0518487L-247.059 0.0354614Z"
                fill="#1F3857"
              />
              <path
                d="M50.1624 81.0032C-57.8371 189.003 -57.8371 364.105 50.1624 472.104C158.162 580.103 333.264 580.103 441.263 472.104C549.263 364.104 549.263 189.003 441.263 81.0032C333.264 -26.9963 158.162 -26.9963 50.1624 81.0032Z"
                fill="#1F3857"
              />
              <path
                d="M314.07 275.68L319.35 273.07C329.97 267.81 338.58 259.21 343.83 248.58L346.45 243.29C346.8 242.57 347.83 242.57 348.18 243.29L350.8 248.58C356.05 259.21 364.66 267.81 375.28 273.06L380.57 275.68C381.29 276.03 381.29 277.06 380.57 277.41L375.28 280.03C364.66 285.29 356.05 293.89 350.8 304.52L348.18 309.82C347.83 310.53 346.81 310.54 346.45 309.82L343.64 304.2C338.34 293.61 329.71 285.06 319.08 279.86L314.08 277.41C313.36 277.06 313.36 276.03 314.08 275.68L314.07 275.68Z"
                fill="#2570C7"
              />
              <path
                d="M110.85 275.68L116.13 273.07C126.75 267.81 135.36 259.21 140.61 248.58L143.23 243.29C143.58 242.57 144.61 242.57 144.96 243.29L147.58 248.58C152.83 259.21 161.44 267.81 172.06 273.06L177.35 275.68C178.07 276.03 178.07 277.06 177.35 277.41L172.06 280.03C161.44 285.29 152.83 293.89 147.58 304.52L144.96 309.82C144.61 310.53 143.59 310.54 143.23 309.82L140.42 304.2C135.12 293.61 126.49 285.06 115.86 279.86L110.86 277.41C110.14 277.06 110.14 276.03 110.86 275.68L110.85 275.68Z"
                fill="#2570C7"
              />
              <path
                d="M244.84 208.19L242.23 202.91C236.97 192.29 228.37 183.68 217.74 178.43L212.45 175.81C211.73 175.46 211.73 174.43 212.45 174.08L217.74 171.46C228.37 166.21 236.97 157.6 242.22 146.98L244.84 141.69C245.19 140.97 246.22 140.97 246.57 141.69L249.19 146.98C254.45 157.6 263.05 166.21 273.68 171.46L278.98 174.08C279.69 174.43 279.7 175.45 278.98 175.81L273.36 178.62C262.77 183.92 254.22 192.55 249.02 203.18L246.57 208.18C246.22 208.9 245.19 208.9 244.84 208.18L244.84 208.19Z"
                fill="#2570C7"
              />
              <path
                d="M244.84 411.41L242.23 406.13C236.97 395.51 228.37 386.9 217.74 381.65L212.45 379.03C211.73 378.68 211.73 377.65 212.45 377.3L217.74 374.68C228.37 369.43 236.97 360.82 242.22 350.2L244.84 344.91C245.19 344.19 246.22 344.19 246.57 344.91L249.19 350.2C254.45 360.82 263.05 369.43 273.68 374.68L278.98 377.3C279.69 377.65 279.7 378.67 278.98 379.03L273.36 381.84C262.77 387.14 254.22 395.77 249.02 406.4L246.57 411.4C246.22 412.12 245.19 412.12 244.84 411.4L244.84 411.41Z"
                fill="#2570C7"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_233">
                <rect
                  width="769.26"
                  height="553.59"
                  fill="white"
                  transform="translate(522.26 553.59) rotate(180)"
                />
              </clipPath>
            </defs>
          </svg>
        </If>
        <If
          condition={
            (backgroundColor === "dark-blue" ||
              backgroundColor === "Midnight Blue") &&
            imageSide === "left"
          }
        >
          <svg
            viewBox="0 0 487 770"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mix-blend-screen absolute bottom-[-100px] right-[-100px] z-0 w-1/2 h-[70%]"
            ref={backgroundEle}
          >
            <g opacity="0.5" clipPath="url(#clip0_31_502)">
              <path
                d="M0.0349731 769.319L553.585 769.222L553.498 269.222L-0.0522715 269.319L0.0349731 769.319Z"
                fill="#1F3857"
              />
              <path
                d="M81.0032 472.098C189.003 580.097 364.105 580.097 472.104 472.098C580.104 364.098 580.104 188.996 472.104 80.9969C364.105 -27.0026 189.003 -27.0026 81.0033 80.9968C-26.9962 188.996 -26.9962 364.098 81.0032 472.098Z"
                fill="#1F3857"
              />
              <path
                d="M275.68 208.19L273.07 202.91C267.81 192.29 259.21 183.68 248.58 178.43L243.29 175.81C242.57 175.46 242.57 174.43 243.29 174.08L248.58 171.46C259.21 166.21 267.81 157.6 273.06 146.98L275.68 141.69C276.03 140.97 277.06 140.97 277.41 141.69L280.03 146.98C285.29 157.6 293.89 166.21 304.52 171.46L309.82 174.08C310.53 174.43 310.54 175.45 309.82 175.81L304.2 178.62C293.61 183.92 285.06 192.55 279.86 203.18L277.41 208.18C277.06 208.9 276.03 208.9 275.68 208.18L275.68 208.19Z"
                fill="#2570C7"
              />
              <path
                d="M275.68 411.41L273.07 406.13C267.81 395.51 259.21 386.9 248.58 381.65L243.29 379.03C242.57 378.68 242.57 377.65 243.29 377.3L248.58 374.68C259.21 369.43 267.81 360.82 273.06 350.2L275.68 344.91C276.03 344.19 277.06 344.19 277.41 344.91L280.03 350.2C285.29 360.82 293.89 369.43 304.52 374.68L309.82 377.3C310.53 377.65 310.54 378.67 309.82 379.03L304.2 381.84C293.61 387.14 285.06 395.77 279.86 406.4L277.41 411.4C277.06 412.12 276.03 412.12 275.68 411.4L275.68 411.41Z"
                fill="#2570C7"
              />
              <path
                d="M208.19 277.42L202.91 280.03C192.29 285.29 183.68 293.89 178.43 304.52L175.81 309.81C175.46 310.53 174.43 310.53 174.08 309.81L171.46 304.52C166.21 293.89 157.6 285.29 146.98 280.04L141.69 277.42C140.97 277.07 140.97 276.04 141.69 275.69L146.98 273.07C157.6 267.81 166.21 259.21 171.46 248.58L174.08 243.28C174.43 242.57 175.45 242.56 175.81 243.28L178.62 248.9C183.92 259.49 192.55 268.04 203.18 273.24L208.18 275.69C208.9 276.04 208.9 277.07 208.18 277.42L208.19 277.42Z"
                fill="#2570C7"
              />
              <path
                d="M411.409 277.42L406.129 280.03C395.509 285.29 386.899 293.89 381.649 304.52L379.029 309.81C378.679 310.53 377.649 310.53 377.299 309.81L374.679 304.52C369.429 293.89 360.819 285.29 350.199 280.04L344.909 277.42C344.189 277.07 344.189 276.04 344.909 275.69L350.199 273.07C360.819 267.81 369.429 259.21 374.679 248.58L377.299 243.28C377.649 242.57 378.669 242.56 379.029 243.28L381.839 248.9C387.139 259.49 395.769 268.04 406.399 273.24L411.399 275.69C412.119 276.04 412.119 277.07 411.399 277.42L411.409 277.42Z"
                fill="#2570C7"
              />
            </g>
            <defs>
              <clipPath id="clip0_31_502">
                <rect
                  width="769.26"
                  height="553.59"
                  fill="white"
                  transform="translate(553.59) rotate(90)"
                />
              </clipPath>
            </defs>
          </svg>
        </If>

        <If condition={backgroundColor?.toLowerCase() === "teal"}>
          {/* <svg
            viewBox="0 0 945 594"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute bottom-[2rem] right-[-200px] z-0 h-[80%]"
            ref={backgroundEle}
          >
            <path
              d="M296.93 593.86C132.94 593.86 0.000346063 460.92 0.000353232 296.93C0.0003604 132.94 132.94 -3.54648e-05 296.93 -2.82966e-05L944.28 0L944.28 593.86L296.93 593.86Z"
              fill="#6F9BA4"
            />
            <path
              d="M89.0993 294.82L93.8892 292.45C103.529 287.68 111.329 279.88 116.089 270.24L118.459 265.44C118.779 264.79 119.709 264.79 120.029 265.44L122.399 270.24C127.169 279.88 134.969 287.68 144.599 292.44L149.399 294.81C150.049 295.13 150.049 296.06 149.399 296.38L144.599 298.75C134.959 303.52 127.159 311.32 122.399 320.96L120.019 325.77C119.699 326.42 118.779 326.42 118.449 325.77L115.899 320.67C111.089 311.07 103.269 303.32 93.6292 298.6L89.0993 296.38C88.4493 296.06 88.4493 295.13 89.0993 294.81L89.0993 294.82Z"
              fill="#9DCFCB"
              fillOpacity="0.5"
            />
            <path
              d="M179.099 402.299L183.889 399.929C193.529 395.159 201.329 387.359 206.089 377.719L208.459 372.919C208.779 372.269 209.709 372.269 210.029 372.919L212.399 377.719C217.169 387.359 224.969 395.159 234.599 399.919L239.399 402.289C240.049 402.609 240.049 403.539 239.399 403.859L234.599 406.229C224.959 410.999 217.159 418.799 212.399 428.439L210.019 433.249C209.699 433.899 208.779 433.899 208.449 433.249L205.899 428.149C201.089 418.549 193.269 410.799 183.629 406.079L179.099 403.859C178.449 403.539 178.449 402.609 179.099 402.289L179.099 402.299Z"
              fill="#9DCFCB"
              fillOpacity="0.5"
            />
          </svg> */}
          <svg
            className="absolute bottom-[10%] right-[-10%] z-0 h-[40dvw]"
            ref={backgroundEle}
            viewBox="0 0 945 593"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M296.93 592.213C132.94 592.213 0.000346063 459.642 0.000353232 296.107C0.0003604 132.571 132.94 -3.53665e-05 296.93 -2.82181e-05L944.28 0L944.28 592.213L296.93 592.213Z"
              fill="#537E85"
            />
            <path
              d="M89.0993 286.672L93.8892 284.309C103.529 279.552 111.329 271.774 116.089 262.16L118.459 257.374C118.779 256.726 119.709 256.726 120.029 257.374L122.399 262.16C127.169 271.774 134.969 279.552 144.599 284.299L149.399 286.662C150.049 286.981 150.049 287.909 149.399 288.228L144.599 290.591C134.959 295.348 127.159 303.126 122.399 312.74L120.019 317.536C119.699 318.185 118.779 318.185 118.449 317.536L115.899 312.451C111.089 302.877 103.269 295.149 93.6292 290.442L89.0993 288.228C88.4493 287.909 88.4493 286.981 89.0993 286.662L89.0993 286.672Z"
              fill="#9DCFCB"
              fillOpacity="0.5"
            />
            <path
              d="M179.099 393.853L183.889 391.49C193.529 386.733 201.329 378.955 206.089 369.341L208.459 364.555C208.779 363.906 209.709 363.906 210.029 364.555L212.399 369.341C217.169 378.955 224.969 386.733 234.599 391.48L239.399 393.843C240.049 394.162 240.049 395.09 239.399 395.409L234.599 397.772C224.959 402.529 217.159 410.307 212.399 419.921L210.019 424.717C209.699 425.365 208.779 425.365 208.449 424.717L205.899 419.631C201.089 410.058 193.269 402.33 183.629 397.623L179.099 395.409C178.449 395.09 178.449 394.162 179.099 393.843L179.099 393.853Z"
              fill="#9DCFCB"
              fillOpacity="0.5"
            />
          </svg>
        </If>

        <If
          condition={
            (imageSide === "left" || !imageSide) &&
            backgroundColor === "Transparent"
          }
        >
          <svg
            viewBox="0 0 420 414"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute left-[60%] top-0 w-[25%]"
            ref={backgroundEle}
          >
            <g opacity="0.2">
              <path
                d="M417.45 207.32H3"
                stroke="#9DCFCB"
                strokeWidth="5"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M404.94 136.36L15.4805 278.11"
                stroke="#9DCFCB"
                strokeWidth="5"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M368.91 73.96L51.4297 340.36"
                stroke="#9DCFCB"
                strokeWidth="5"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M313.72 27.6499L106.49 386.57"
                stroke="#9DCFCB"
                strokeWidth="5"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M246.01 3L174.04 411.16"
                stroke="#9DCFCB"
                strokeWidth="5"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M173.95 3L245.92 411.16"
                stroke="#9DCFCB"
                strokeWidth="5"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M106.25 27.6499L313.47 386.57"
                stroke="#9DCFCB"
                strokeWidth="5"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M51.0498 73.96L368.54 340.36"
                stroke="#9DCFCB"
                strokeWidth="5"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M15.0195 136.36L404.48 278.11"
                stroke="#9DCFCB"
                strokeWidth="5"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M250.073 247.159C272.08 225.153 272.08 189.475 250.074 167.469C228.068 145.463 192.389 145.462 170.383 167.469C148.377 189.475 148.377 225.153 170.383 247.16C192.389 269.166 228.067 269.165 250.073 247.159Z"
                fill="#9DCFCB"
              />
            </g>
          </svg>
        </If>

        <If condition={backgroundColor === "Marquardt Green"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 1692 587"
            className="absolute right-[-50dvw] bottom-0 mix-blend-screen h-[70%] z-0 opacity-50"
            ref={backgroundEle}
          >
            <path
              fill="#4D7963"
              d="m1691.05 554.473-.1-553.55-1334.175.233.097 553.55 1334.178-.233Z"
            />
            <path
              fill="#4D7963"
              d="M559.651 473.651c108-107.999 108-283.101 0-391.1-107.999-108-283.101-108-391.101 0-108 107.999-108 283.101 0 391.1 108 108 283.102 108 391.101 0Z"
            />
            <g fill="#80B09A" opacity=".5">
              <path d="m208.19 310.91-5.28 2.61a54.052 54.052 0 0 0-24.48 24.49l-2.62 5.29a.963.963 0 0 1-1.73 0l-2.62-5.29a53.983 53.983 0 0 0-24.48-24.48l-5.29-2.62a.963.963 0 0 1 0-1.73l5.29-2.62a54.052 54.052 0 0 0 24.48-24.49l2.62-5.3a.966.966 0 0 1 1.73 0l2.81 5.62a53.997 53.997 0 0 0 24.56 24.34l5 2.45c.72.35.72 1.38 0 1.73h.01ZM411.41 310.91l-5.28 2.61a54.052 54.052 0 0 0-24.48 24.49l-2.62 5.29a.963.963 0 0 1-1.73 0l-2.62-5.29a53.983 53.983 0 0 0-24.48-24.48l-5.29-2.62a.963.963 0 0 1 0-1.73l5.29-2.62a54.052 54.052 0 0 0 24.48-24.49l2.62-5.3a.966.966 0 0 1 1.73 0l2.81 5.62a53.997 53.997 0 0 0 24.56 24.34l5 2.45c.72.35.72 1.38 0 1.73h.01ZM277.42 378.4l2.61 5.28a54.052 54.052 0 0 0 24.49 24.48l5.29 2.62c.72.35.72 1.38 0 1.73l-5.29 2.62a53.983 53.983 0 0 0-24.48 24.48l-2.62 5.29a.963.963 0 0 1-1.73 0l-2.62-5.29a54.052 54.052 0 0 0-24.49-24.48l-5.3-2.62a.966.966 0 0 1 0-1.73l5.62-2.81a53.997 53.997 0 0 0 24.34-24.56l2.45-5a.963.963 0 0 1 1.73 0v-.01ZM277.42 175.18l2.61 5.28a54.052 54.052 0 0 0 24.49 24.48l5.29 2.62c.72.35.72 1.38 0 1.73l-5.29 2.62a53.983 53.983 0 0 0-24.48 24.48l-2.62 5.29a.963.963 0 0 1-1.73 0l-2.62-5.29a54.052 54.052 0 0 0-24.49-24.48l-5.3-2.62a.966.966 0 0 1 0-1.73l5.62-2.81a53.997 53.997 0 0 0 24.34-24.56l2.45-5a.963.963 0 0 1 1.73 0v-.01Z" />
            </g>
          </svg>
        </If>
        <If condition={backgroundColor === "Marquardt Light Green"}>
          <svg
            viewBox="0 0 594 872"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute left-20 h-[90%] top-[-100px] z-0"
            ref={backgroundEle}
          >
            <path
              d="M594 597.799C594 749.236 461.029 872 297 872C132.971 872 0 749.236 0 597.799L0 0L594 0L594 597.799Z"
              fill="#80B09A"
            />
            <path
              d="M294.821 783.181L292.451 778.391C287.681 768.751 279.881 760.951 270.241 756.191L265.441 753.821C264.791 753.501 264.791 752.571 265.441 752.251L270.241 749.881C279.881 745.111 287.681 737.311 292.441 727.681L294.811 722.881C295.131 722.231 296.061 722.231 296.381 722.881L298.751 727.681C303.521 737.321 311.321 745.121 320.961 749.881L325.771 752.261C326.421 752.581 326.421 753.501 325.771 753.831L320.671 756.381C311.071 761.191 303.321 769.011 298.601 778.651L296.381 783.181C296.061 783.831 295.131 783.831 294.811 783.181H294.821Z"
              fill="#CDE0D7"
            />
            <path
              d="M402.299 693.181L399.929 688.391C395.159 678.751 387.359 670.951 377.719 666.191L372.919 663.821C372.269 663.501 372.269 662.571 372.919 662.251L377.719 659.881C387.359 655.111 395.159 647.311 399.919 637.681L402.289 632.881C402.609 632.231 403.539 632.231 403.859 632.881L406.229 637.681C410.999 647.321 418.799 655.121 428.439 659.881L433.249 662.261C433.899 662.581 433.899 663.501 433.249 663.831L428.149 666.381C418.549 671.191 410.799 679.011 406.079 688.651L403.859 693.181C403.539 693.831 402.609 693.831 402.289 693.181H402.299Z"
              fill="#CDE0D7"
            />
          </svg>
        </If>
        <If
          condition={imageSide === "right" && backgroundColor === "Transparent"}
        >
          <svg
            viewBox="0 0 271 271"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute left-[15rem] top-0"
            ref={backgroundEle}
          >
            <path
              d="M168.65 136.26L163.37 138.87C152.75 144.13 144.14 152.73 138.89 163.36L136.27 168.65C135.92 169.37 134.89 169.37 134.54 168.65L131.92 163.36C126.67 152.73 118.06 144.13 107.44 138.88L102.15 136.26C101.43 135.91 101.43 134.88 102.15 134.53L107.44 131.91C118.06 126.65 126.67 118.05 131.92 107.42L134.54 102.12C134.89 101.41 135.91 101.4 136.27 102.12L139.08 107.74C144.38 118.33 153.01 126.88 163.64 132.08L168.64 134.53C169.36 134.88 169.36 135.91 168.64 136.26H168.65Z"
              fill="#9DCFCB"
              fillOpacity="0.2"
            />
            <path
              d="M67.04 136.26L61.76 138.87C51.14 144.13 42.53 152.73 37.28 163.36L34.66 168.65C34.31 169.37 33.28 169.37 32.93 168.65L30.31 163.36C25.06 152.73 16.4499 144.13 5.82992 138.88L0.54 136.26C-0.18 135.91 -0.18 134.88 0.54 134.53L5.82992 131.91C16.4499 126.65 25.06 118.05 30.31 107.42L32.93 102.12C33.28 101.41 34.3 101.4 34.66 102.12L37.4699 107.74C42.7699 118.33 51.4 126.88 62.03 132.08L67.03 134.53C67.75 134.88 67.75 135.91 67.03 136.26H67.04Z"
              fill="#9DCFCB"
              fillOpacity="0.2"
            />
            <path
              d="M270.26 136.26L264.98 138.87C254.36 144.13 245.75 152.73 240.5 163.36L237.88 168.65C237.53 169.37 236.5 169.37 236.15 168.65L233.53 163.36C228.28 152.73 219.67 144.13 209.05 138.88L203.76 136.26C203.04 135.91 203.04 134.88 203.76 134.53L209.05 131.91C219.67 126.65 228.28 118.05 233.53 107.42L236.15 102.12C236.5 101.41 237.52 101.4 237.88 102.12L240.69 107.74C245.99 118.33 254.62 126.88 265.25 132.08L270.25 134.53C270.97 134.88 270.97 135.91 270.25 136.26H270.26Z"
              fill="#9DCFCB"
              fillOpacity="0.2"
            />
            <path
              d="M136.27 203.75L138.88 209.03C144.14 219.65 152.74 228.26 163.37 233.51L168.66 236.13C169.38 236.48 169.38 237.51 168.66 237.86L163.37 240.48C152.74 245.73 144.14 254.34 138.89 264.96L136.27 270.25C135.92 270.97 134.89 270.97 134.54 270.25L131.92 264.96C126.66 254.34 118.06 245.73 107.43 240.48L102.13 237.86C101.42 237.51 101.41 236.49 102.13 236.13L107.75 233.32C118.34 228.02 126.89 219.39 132.09 208.76L134.54 203.76C134.89 203.04 135.92 203.04 136.27 203.76V203.75Z"
              fill="#9DCFCB"
              fillOpacity="0.2"
            />
            <path
              d="M136.27 0.52999L138.88 5.81002C144.14 16.43 152.74 25.04 163.37 30.29L168.66 32.9099C169.38 33.2599 169.38 34.2901 168.66 34.6401L163.37 37.26C152.74 42.51 144.14 51.12 138.89 61.74L136.27 67.03C135.92 67.75 134.89 67.75 134.54 67.03L131.92 61.74C126.66 51.12 118.06 42.51 107.43 37.26L102.13 34.6401C101.42 34.2901 101.41 33.2699 102.13 32.9099L107.75 30.1001C118.34 24.8001 126.89 16.17 132.09 5.54L134.54 0.54C134.89 -0.18 135.92 -0.18 136.27 0.54V0.52999Z"
              fill="#9DCFCB"
              fillOpacity="0.2"
            />
          </svg>
        </If>

        <If condition={backgroundColor?.toLowerCase() === "orange"}>
          <svg
            viewBox="0 0 1021 553"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute top-[20%] right-[-200px] z-0 h-[50%]"
            ref={backgroundEle}
          >
            <path
              d="M276.01 552.02C123.57 552.02 0 428.44 0 276.01C0 123.57 123.58 0 276.01 0H1350.3V552.02H276.01Z"
              fill="#FFBD6F"
            />
            <path
              d="M504.96 277.42H90.5098"
              stroke="#FFB154"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M492.45 206.46L102.99 348.21"
              stroke="#FFB154"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M456.419 144.06L138.939 410.46"
              stroke="#FFB154"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M401.23 97.75L194 456.67"
              stroke="#FFB154"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M333.52 73.1001L261.55 481.26"
              stroke="#FFB154"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M261.46 73.1001L333.43 481.26"
              stroke="#FFB154"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M193.76 97.75L400.98 456.67"
              stroke="#FFB154"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M138.56 144.06L456.05 410.46"
              stroke="#FFB154"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M102.529 206.46L491.989 348.21"
              stroke="#FFB154"
              strokeWidth="5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M337.23 237.922C359.041 259.733 359.04 295.095 337.23 316.906C315.419 338.717 280.057 338.717 258.246 316.906C236.435 295.095 236.435 259.733 258.246 237.922C280.057 216.111 315.419 216.111 337.23 237.922Z"
              fill="#FFB154"
              stroke="#FFB154"
            />
          </svg>
        </If>

        <If condition={imageSide === "left"}>
          <ImageSide data={card} />
          <TextSide data={card} />
        </If>

        <If condition={imageSide === "right" || !imageSide}>
          <TextSide data={card} />
          <ImageSide data={card} />
        </If>
      </article>
    </div>
  );
};

/**
 *
 * CardBlock
 *
 */
const CardBlock = ({
  data = {
    cardsCollection: {
      items: [],
    },
    title: "",
    showTitle: false,
    type: "default",
  },
  isInterior = false,
  ...props
}) => {
  if (data.type === "grid" || data.type === "slider") {
    return <CardGrid data={data} isInterior={isInterior} />;
  }

  return (
    <Stack
      as="section"
      gap={isInterior ? "none" : "page"}
      {...props}
      className={"overflow-hidden"}
    >
      <If condition={data?.showTitle}>
        <H2 className="px-gutter my-32">{data.title}</H2>
      </If>
      <For
        each={data?.cardsCollection?.items || []}
        render={(card, index) => (
          <Card key={index} data={card} isInterior={isInterior} />
        )}
      />
    </Stack>
  );
};

export default CardBlock;
