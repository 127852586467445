"use client";

import React, { useEffect, useRef } from "react";
import styles from "./BrandTruth.module.scss";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import dynamic from "next/dynamic";

const Splitting = dynamic(() => import("splitting"));

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

/**
 *
 * BrandTruth
 *
 */
const BrandTruth = ({ data = { truthText: "" }, className = "", ...props }) => {
  const ref = useRef(null);
  const observerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.visible);
          } else {
            entry.target.classList.remove(styles.visible);
          }
        });
      },
      { threshold: 0.01 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, []);

  useGSAP(
    () => {
      if (!gsap && typeof window === "undefined") {
        return;
      }
      const run = async () => {
        const { default: Splitting } = await import("splitting");
        const results = Splitting({ target: ref.current, by: "words" });
        let i = 0;

        const tl = gsap.timeline({
          paused: true,
          scrollTrigger: {
            trigger: ref.current.parentElement,
            scrub: true,
            id: `brand-truths`,
            start: "top center",
            end: `bottom center`,
          },
        });
        const words = results?.[0].words;
        words.forEach((word, idx) => {
          tl.to(word, {
            color: "#fff",
          });

          if (words[idx - 1]) {
            tl.to(words[idx - 1], {
              color: "inherit",
            });
          }

          if (words[idx + 1]) {
            tl.to(words[idx + 1], {
              color: "#fff",
            });
          }
        });
      };

      run().then(() => {});
    },
    {
      dependencies: [ref.current],
    }
  );

  return (
    <section
      className={`${styles["c-brand-truth"]}  ${className} relative z-[1] py-40 grid`}
      ref={observerRef}
    >
      <div className={styles.text} ref={ref}>
        {data.truthText}
      </div>
    </section>
  );
};

export default BrandTruth;
