import React from "react";
import styles from "./Modal.module.scss";

import * as Dialog from "@radix-ui/react-dialog";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { options } from "@/config/contentful";

/**
 *
 * Modal
 *
 */
const Modal = ({
  onClose = () => {},
  name = "",
  title = "",
  image = {},
  description = {},
  ...props
}) => {
  return (
    <Dialog.Root
      defaultOpen
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Dialog.Portal>
        <Dialog.Overlay className="bg-blackA6 data-[state=open]:animate-overlayShow fixed inset-0 z-50" />
        <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] h-[85vh] w-[90vw] translate-x-[-50%] translate-y-[-50%] rounded bg-cream z-[51]">
          <div
            className="grid grid-cols-1 md:grid-cols-2 px-gutter gap-12 h-full pb-10 overflow-hidden"
            data-lenis-prevent
          >
            <div>
              <img
                src={image?.url}
                alt={image?.description}
                className="rounded mt-10"
              />
            </div>
            <div className="h-full overflow-auto">
              <header className="pt-14">
                <h2 className="pt-12 text-card-title font-bold">{name}</h2>

                <p>{title}</p>
              </header>
              <div className="pt-12 stack gap-6">
                {documentToReactComponents(description?.json, options)}
              </div>
            </div>
          </div>
          <Dialog.Close asChild>
            <button
              className="absolute top-2 right-4 px-4 py-2 border rounded"
              aria-label="Close"
            >
              Close
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default Modal;
