"use client";

import { useEffect, useRef, useState } from "react";
import Play from "./icons/Play";
import If from "./If";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

export default function Video({
  src = "",
  title = "Illuminus Video",
  ...props
}) {
  const ref = useRef(null);
  const [playerSrc, setPlayerSrc] = useState(src);
  const [player, setPlayer] = useState(null);
  const [state, setState] = useState("idle");
  const backgroundEle = useRef(null);

  useGSAP(
    () => {
      if (!gsap || !backgroundEle.current || typeof window === "undefined") {
        return;
      }

      const tl = gsap.timeline({
        paused: true,
        scrollTrigger: {
          trigger: backgroundEle.current,
          start: window.innerWidth > 600 ? "top bottom" : "top bottom",
          end: window.innerWidth > 600 ? `bottom bottom` : "bottom bottom",
          scrub: 0.25,
          onLeave: () => {
            // ref.current.style.clipPath = "inset(0 0 0 0 round 0)";
          },
        },
      });

      tl.to(backgroundEle.current, {
        borderRadius: "0",
        transform: "translateY(0) scale(1)",
        ease: "none",
        onEnter: () => {},
        onEnterBack: () => {},
        onComplete: () => {
          backgroundEle.current.style.clipPath = "inset(0 0 0 0 round 0)";
        },
      });
    },
    {
      dependencies: [backgroundEle.current],
    }
  );

  // useEffect(() => {
  //   if (!src) return;
  //   // const _player = new Player(ref.current, {
  //   //   url: playerSrc,
  //   // });

  //   // _player.controls = false;

  //   // // _player.on("play", () => setState("playing"));
  //   // // _player.on("pause", () => setState("paused"));
  //   // // _player.on("ended", () => setState("ended"));

  //   // setPlayer(_player);

  //   // return () => _player.destroy();
  // }, [playerSrc]);

  return (
    <div key={playerSrc} className="relative z-0">
      <div
        className="relative z-[1] overflow-hidden"
        ref={backgroundEle}
        style={{
          // clipPath: "inset(5% 5% 5% 5% round 2rem)",
          borderRadius: "2rem",
          transform: "translateY(100px) scale(0.95)",
          transformOrigin: "center",
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src={playerSrc}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          className="aspect-video w-full"
          loading="lazy"
          title={title}
        />

        <If condition={state !== "playing"}>
          <div className="absolute inset-0 rounded overflow-hidden z-10">
            <span className="sr-only">Play video</span>
            <button
              className="absolute bottom-10 left-10  z-10 w-[88px]"
              onClick={() => {
                if (state === "playing") {
                  setPlayerSrc(src);
                } else {
                  setState("playing");
                  setPlayerSrc(
                    src.replace("&controls=0", "").replace("&background=1", "")
                  );
                }
              }}
            >
              <span className="sr-only">Play</span>
              <Play width={88} />
            </button>
          </div>
        </If>
      </div>
    </div>
  );
}
