"use client";

import styles from "./TextInput.module.scss";

import React, { useState } from "react";

const PhoneInput = ({
  label = "",
  name = "",
  value = "",
  placeholder = "",
  background = "bg-transparent",
  required = false,
  type = "text",
  ...props
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const formattedNumber = formatPhoneNumber(input);
    setPhoneNumber(formattedNumber);
  };

  const formatPhoneNumber = (input: string) => {
    // Remove all non-digit characters from the input
    const cleanedInput = input.replace(/\D/g, "");

    // Format the phone number as (XXX) XXX-XXXX
    const formattedNumber = cleanedInput.replace(
      /^(\d{3})(\d{3})(\d{4})$/,
      "($1) $2-$3"
    );

    return formattedNumber;
  };

  const isValidPhoneNumber = () => {
    // Validate the phone number using a regular expression
    const phoneNumberRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    return phoneNumberRegex.test(phoneNumber);
  };

  return (
    <div className={styles["text-input"]} {...props}>
      <label htmlFor={name} className="sr-only">
        {label}
      </label>
      <input
        type={type}
        name={name}
        placeholder={placeholder || label}
        className={`rounded-button border border-border-color color-border-color w-full h-[70px] flex justify-between items-center px-10 leading-none ${background}`}
        required={required}
        value={phoneNumber}
        onChange={handleChange}
      />
    </div>
  );
};

export default PhoneInput;
