export const BGColors = {
  Blue: "bg-blue",
  "Dark Blue": "bg-dark-blue",
  "Midnight Blue": "bg-dark-blue",
  "Light Blue": "bg-light-blue",
  Orange: "bg-orange",
  "Light Teal": "bg-light-teal",
  Teal: "bg-lighter-teal",
  teal: "bg-lighter-teal",
  Green: "bg-green",
  "Light Green": "bg-light-green",
  Purple: "bg-purple",
  Red: "bg-red",
  "Campus Orange": "bg-campus-orange",
  "Campus Purple": "bg-campus-purple",
  "Campus Red": "bg-campus-red",
  White: "bg-white",
  Cream: "bg-cream",
  Transparent: "bg-transparent",
  "Marquardt Green": "bg-green",
  "Marquardt Light Green": "bg-light-green",
  "Campus Teal": "bg-campus-teal",
  "Campus Light Maroon": "bg-campus-light-red",
};

export const TextColors = {
  White: "text-white",
  Black: "text-black",
  Blue: "text-blue",
  "Dark Blue": "text-dark-blue",
  "Light Blue": "text-light-blue",
  Orange: "text-orange",
  "Light Teal": "text-light-teal",
  Teal: "text-teal",
  Green: "text-green",
  "Light Green": "text-light-green",
  Purple: "text-purple",
  Red: "text-red",
  "Campus Orange": "text-campus-orange",
  "Campus Purple": "text-campus-purple",
  "Campus Red": "text-campus-red",
  Cream: "text-cream",
};

export const BGTextColors = {
  Blue: "text-white",
  "Dark Blue": "text-white",
  "Light Blue": "text-dark-blue",
  Orange: "text-dark-blue",
  "Light Teal": "text-darl-blue",
  Teal: "text-white",
  Green: "text-white",
  "Light Green": "text-dark-blue",
  Purple: "text-white",
  Red: "text-white",
  "Campus Orange": "text-dark-blue",
  "Campus Purple": "text-white",
  "Campus Red": "text-white",
  White: "text-dark-blue",
  Cream: "text-dark-blue",
  Transparent: "text-dark-blue",
};
